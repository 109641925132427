import React from 'react'
import styled from 'styled-components'
import t from '../../translation'
import Section from '../components/Section'
import { playVideo } from '../components/VideoPlayer'
import { DualText } from './styles'

const TeachersSection = props => {
  const handleClickVideo = () => {
    playVideo({ videoLink: t`links.app-into-video` })
  }

  return (
    <Section background='var(--light-grey)' style={{ padding: '30px 0px' }}>
      <DualText align='center'>
        <div>{t`s.teacher.title`}</div>
      </DualText>
      <Wrap>
        <Item>
          <div>{t`s.teacher.item.1.title`}</div>
          <ThumbnailContainer onClick={handleClickVideo}>
            <VideoThumbnail
              style={{ cursor: 'pointer' }}
              src='https://www.solarforschools.co.uk/get-file/?path=/appwebsite/intro-thumbnail.png'
              alt=''
            />
            <PlayIcon src='/images/play-icon.png' />
          </ThumbnailContainer>
          <div>{t`s.teacher.item.1.text.1`}</div>
        </Item>
        <Item>
          <div>{t`s.teacher.item.2.title`}</div>
          <img src='/images/homepage/handbook.png' alt='' />
          <div>
            <div>
              <span><a href={t`links.handbook`} download>{t`s.teacher.item.2.link.1`}</a></span>
              <span>{t`s.teacher.item.2.text.1`}</span>
            </div>
            <div>
              <span><a href={t`links.handbook.pbl`} download>{t`s.teacher.item.2.link.2`}</a></span>
              <span>{t`s.teacher.item.2.text.2`}</span>
            </div>
          </div>
        </Item>
        <Item>
          <div>{t`s.teacher.item.3.title`}</div>
          <img src='/images/homepage/online-safety.svg' alt='' />
          <div>
            <span>{t`s.teacher.item.3.text.1`}</span>
            <span><a href='/online-safety'>{t`s.teacher.item.3.link.1`}</a></span>
            <span>{t`s.teacher.item.3.text.2`}</span>
          </div>
        </Item>
      </Wrap>
    </Section>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 20px;
  > * {
    margin: 15px;
  }
`

const ThumbnailContainer = styled.div`
  position: relative;
`

const PlayIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  cursor: pointer;
`

const VideoThumbnail = styled.img`
  object-fit: cover !important;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

const Item = styled.div`
  min-height: 400px;
  width: 380px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
  background: white;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
  justify-content: space-between;

  > *:nth-child(1) {
    font-size: 36px;
    margin-bottom: 10px;
    text-align: center;
  }

  > *:nth-child(2) {
    height: 165px;
    object-fit: contain;
    margin: 20px 0px;
  }

  > *:nth-child(3) {
    font-size: 18px;
  }

  a {
    color: inherit;
    font-weight: 600;
  }

  @media(max-width: 767px) {
    height: fit-content;
    min-height: 300px;
    width: 300px;
    
    > *:nth-child(1) {
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
    }
  
    > *:nth-child(2) {
      height: 100px;
      margin: 20px 0px;
    }
  
    > *:nth-child(3) {
      font-size: 14px;
    }
  }
`

export default TeachersSection
