import React from 'react'
import styled from 'styled-components'
import t from '../../translation'

const DownloadLinks = props => {
  return (
    <Container>
      <a href={t`links.app-store`}>
        <img src='/images/homepage/app-store.svg' alt='' />
      </a>
      <a href={t`links.play-store`}>
        <img src='/images/homepage/play-store.svg' alt='' />
      </a>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  img {
    height: 60px;
    object-fit: contain;
    margin: 5px 0px;
    margin-right: 15px;
  }
`

export default DownloadLinks
